<template>
    <dashboardPagesLayout>
        <div class="p-inside-manage">
            <div class="page_title">Orders</div>
            <div class="wrap_manage_table under_construction">
                <span>In development</span>
            </div>
        </div>
    </dashboardPagesLayout>
</template>

<script>
import dashboardPagesLayout from "@/layouts/dashboardPagesLayout";
export default {
    name: "orders",
    components: {
        dashboardPagesLayout
    },
}
</script>

<style scoped>

</style>